<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateAutoridad">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input v-model="autoridad.name" class="form-control" id="name" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/autoridades";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      autoridad: {
        name: "",
      },
      autoridadId: this.$route.params.id,
    };
  },
  mounted() {
    this.getAutoridad();
  },
  methods: {
    getAutoridad() {
      if (!this.autoridadId) {
        return;
      }
      find(this.autoridadId).then((response) => {
        this.autoridad = response.data;
      });
    },
    saveOrUpdateAutoridad() {
      if (this.autoridadId) {
        this.updateAutoridad();
      } else {
        this.saveAutoridad();
      }
    },
    saveAutoridad() {
      create(this.autoridad).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/autoridades-regulatorias");
      });
    },
    updateAutoridad() {
      update(this.autoridadId, this.autoridad).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/autoridades-regulatorias");
      });
    },
  },
};
</script>
